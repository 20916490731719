<template>
  <div class="c-app modal-vue add-miners">
    <CWrapper class="bg-bg-gray pt-2">
      <CForm>
        <CRow class="add-miners__wrap">
          <CCol md="8" class="mb-4">
            <div class="c-title">{{ $t("Add single miner") }}</div>
          </CCol>
          <CCol md="4" class="mb-4">
            <router-link
              to="/AddMiners"
              class="button-blue text-uppercase d-flex justify-content-center align-items-center text-decoration-none"
            >
              {{ $t("Add multiple") }}</router-link
            >
          </CCol>
          <CCol md="8">
            <p class="mb-2">
              <!-- <label for="first_name">Model</label> -->
              <CSelect
                label="Model"
                @update:value="updateValue"
                name="model"
                value=""
                :options="[
                  '',
                  'test miner',
                  'antminer-s19I -885',
                  'innosilicon t3+',
                  'whatsminer m21s',
                  'whatsminer m31s',
                  'whatsminer m32s',
                  'antminer-s9',
                  'antminer-s19I -920',
                  'avalon 1066',
                  'avalon 852',
                ]"
              />
            </p>
          </CCol>
          <CCol md="4">
            <p class="mb-2">
              <label>Power consumption</label>
              <CInput class="" v-model="formFields.exp_powerrate" />
            </p>
          </CCol>
          <CCol md="8">
            <p class="mb-2">
              <label for="first_name">Title</label>
              <CInput class="" v-model="formFields.name" disabled />
            </p>
          </CCol>
          <CCol md="4">
            <p class="mb-2">
              <label>Expected temperature</label>
              <CInput class="" v-model="formFields.exp_temperature" />
            </p>
          </CCol>
          <CCol md="8">
            <p class="mb-2">
              <!-- <CInput class="" id="location" /> -->
              <CSelect
                label="Сrawler type"
                name="crawler"
                @update:value="updateValue"
                value=""
                :options="[
                  '',
                  'TestMiner',
                  'Antminer',
                  'Avalon',
                  'InnosiliconApi',
                  'Whatsminer',
                  'Innosilicon',
                ]"
              />
            </p>
          </CCol>
          <CCol md="4">
            <p class="mb-2">
              <label>Settings</label>
              <CTextarea class="" v-model="formFields.setting" />
            </p>
          </CCol>
          <CCol md="8">
            <p class="mb-2">
              <label>MAC-address</label>
              <CInput class="text-black" v-model="formFields.mac_address" readonly />
            </p>
          </CCol>
          <CCol md="12">
            <div class="d-flex align-items-center flex-wrap">
              <CCol md="8" class="pl-0">
                <p class="mb-2">
                  <label>IP</label>
                  <CInput class="" v-model="formFields.ip_address" />
                </p>
              </CCol>
              <CCol md="4" class="pr-0 mt-4">
                <CButton
                  class="button-blue text-uppercase mt-2"
                  :disabled="checkAllFields()"
                  @click="checkConnection()"
                  v-if="!check.connection"
                  >{{ $t("check connection") }}</CButton
                >
                <div>
                  <CSpinner color="main_color" v-if="check.connection" />
                </div>
                <span class="font-sm text-dark mt-1" v-if="!choosedCrawler">
                  {{ $t("First select a crawler") }}</span
                >
              </CCol>
            </div>
          </CCol>
          <CCol md="8">
            <p class="mb-2">
              <label for="first_name">Expected Hashrate (Gh/s) </label>
              <CInput class="" v-model="formFields.exp_hashrate" />
            </p>
          </CCol>
          <CCol md="8">
            <p class="mb-2">
              <!-- <CInput class="" id="location" /> -->
              <CSelect
                label="Owner"
                name="user_id"
                value=""
                @update:value="updateValue"
                :options="[
                  '',
                  ...getters.owners.map((e) => {
                    return { ...e, value: e.email };
                  }),
                ]"
              />
            </p>
          </CCol>
          <CCol md="8">
            <p class="mb-2">
              <!-- <CInput class="" id="location" /> -->
              <!-- <CSelect
                label="Pool"
                name="pool_id"
                value=""
                @update:value="updateValue"
                :options="
                  getters.pools.data
                    ? [
                        '',
                        ...getters.pools.data.map((e) => {
                          return { ...e, value: e.name };
                        }),
                      ]
                    : []
                "
              /> -->
            </p>
          </CCol>
          <CCol md="4">
            <p class="mb-2">
              <!-- 10.42.1.22 -->
              <!-- <CSelect
                label="Worker"
                name="worker_id"
                value=""
                @update:value="updateValue"
                :options="
                  getters.workers
                    ? ['', ...getters.workers.map((res) => {
                          return { value: res.username, ...res };
                        })]
                    : []
                "
              /> -->
            </p>
          </CCol>
          <CCol md="8">
            <p class="mb-2">
              <!-- <CInput class="" id="location" /> -->
              <CSelect
                label="Farm"
                name="farm_id"
                value=""
                @update:value="updateValue"
                :options="getters.farms ? ['', ...getters.farms] : []"
              />
            </p>
          </CCol>
          <CCol md="4">
            <p class="mb-2">
              <!-- <CInput class="" id="location" /> -->
              <CSelect
                label="Container"
                value=""
                name="container_id"
                @update:value="updateValue"
                :options="getters.containers ? ['', ...getters.containers] : []"
              />
            </p>
          </CCol>
          <CCol md="8">
            <p class="mb-2">
              <label>Section</label>
              <CInput class="" v-model="formFields.section" />
            </p>
          </CCol>
          <CCol md="4">
            <p class="mb-2">
              <label for="first_name">Position</label>
              <CInput class="" v-model="formFields.position" />
            </p>
          </CCol>
          <CCol md="8" class="addMiner mt-0">
            <p class="mb-2 calendar">
              <label for="first_name"> {{ $t("Installation date") }}</label>
              <Datepicker :format="customFormatter" v-model="formFields.created_at" />
            </p>
          </CCol>
          <CCol md="8">
            <CCol md="8" class="pl-0">
              <div class="errors">
                <p class="text-error_color">
                  {{ errors }}
                </p>
              </div>
              <div class="d-flex justify-content-between mt-4">
                <CButton
                  class="button-blue ml-2 text-uppercase"
                  @click="addNewMiner()"
                  v-if="!check.submitted"
                  :disabled="formFields.mac_address.length === 0"
                >
                  {{ $t("Add") }}</CButton
                >
                <CSpinner color="main_color" v-if="check.submitted" />
              </div>
            </CCol>
          </CCol>
        </CRow>
      </CForm>
    </CWrapper>
    <MinerError
      :isOpened="isOpenedModal"
      @changeOpened="updateOpened"
      :miners="invalidMiner"
      :msg="msg"
    />
  </div>
</template>

<script>
import { storeParent } from "../../api/composition/filtering";
import { computed, reactive, ref } from "@vue/composition-api";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import MinerError from "../Modals/MinerError";

export default {
  name: "AddMiner",
  components: {
    MinerError,
    Datepicker,
  },
  setup(props, { root }) {
    const _store = storeParent(root);
    const { startLoading, stopLoading } = _store.helpers().loaders;
    const isOpenedModal = ref(false);
    const ipRegex = ref(
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
    );
    const formFields = reactive({
      name: "",
      mac_address: "",
      ip_address: "",
      model: "",
      user_id: "",
      pool_id: "",
      worker_id: "",
      farm_id: "",
      container_id: "",
      section: "",
      position: "",
      crawler: "",
      exp_hashrate: "",
      created_at: new Date(),
      exp_temperature: "79",
      exp_powerrate: "",
      status: "active",
      description: "field",
      setting: "{}",
    });
    const choosedCrawler = ref(false);
    const minersModels = reactive({
      "test miner": {
        exp_hashrate: 57500,
        exp_powerrate: 3300,
        short: 'test',
      },
      "antminer-s19I -885": {
        exp_hashrate: 57500,
        exp_powerrate: 3300,
        short: '885',
      },
      "innosilicon t3+": {
        exp_hashrate: 57500,
        exp_powerrate: 3300,
        short: 't3+',
      },
      "whatsminer m21s": {
        exp_hashrate: 56000,
        exp_powerrate: 3360,
        short: 'm21s',
      },
      "whatsminer m31s": {
        exp_hashrate: 70000,
        exp_powerrate: 3220,
        short: 'm31s',
      },
      "avalon 1066": {
        exp_hashrate: 48500,
        exp_powerrate: 3250,
        short: '1066',
      },
      "antminer-s19I -920": {
        exp_hashrate: 92000,
        exp_powerrate: 3230,
        short: '920',
      },
      "whatsminer m32s": {
        exp_hashrate: 61000,
        exp_powerrate: 3420,
        short: 'm32s',
      },
      "avalon 852": {
        exp_hashrate: 56000,
        exp_powerrate: 3360,
        short: '852',
      },
      "antminer-s9": {
        exp_hashrate: 56000,
        exp_powerrate: 3360,
        short: 's9',
      },
    });
    const errors = ref("");
    const msg = ref("");
    const invalidMiner = ref(null);
    const check = reactive({
      connection: false,
      submitted: false,
    });
    const _dispatch = _store.dispatchComposition();

    _dispatch.getFarms();
    _dispatch.getPools();
    _dispatch.getContainers();
    _dispatch.getOwners();
    // _dispatch.getContainer(1);

    const getters = computed(() => _store.gettersComposition());

    const checkConnection = () => {
      startLoading(check, "connection");

      _dispatch
        .checkConnection(formFields)
        .then((res) => {
          formFields.mac_address = res.macaddr;
          const splitted = formFields.ip_address.split(".");
          formFields["section"] = splitted[2];
          formFields["position"] = splitted[3] % 10;
          const d = new Date();
          const generateNameDate = d.getFullYear().toString().substring(2, 4) + (d.getMonth().toString().length === 2 ? d.getMonth().toString() : "0" + d.getMonth().toString());
          const generateNameIP = formFields.ip_address;
          const generatedName = generateNameDate + "-" + generateNameIP + "-" + minersModels[formFields.model].short;

          formFields.name = generatedName;
        })
        .catch((res) => {
          if (res.msg) {
            invalidMiner.value = res.data;
            isOpenedModal.value = true;
            msg.value = res.msg;
          } else if (res.response && res.response.status === 500) {
            msg.value = "Miner doesn't exist";
            isOpenedModal.value = true;
          }
        })
        .then(() => {
          stopLoading(check, "connection");
        });
    };

    const addNewMiner = () => {
      startLoading(check, "submitted");
      if (formFields.worker_id === "") formFields.worker_id = null;
      _dispatch
        .postMiner(formFields)
        .then(() => {
          root.$router.push("/Miners/1");
        })
        .catch((res) => {
          if (res.response.status === 422) {
            const keys = Object.keys(res.response.data.errors);
            if (keys.length > 0) errors.value = res.response.data.errors[keys[0]][0];
          }
        })
        .then(() => {
          stopLoading(check, "submitted");
        });
    };

    const updateOpened = (e) => {
      isOpenedModal.value = e;
    };

    const updateValue = (value, e) => {
      const id = e.target.selectedOptions[0].dataset.key;
      switch (e.target.name) {
        case "pool_id":
          value = getters.value.pools.data[id].id;
          break;
        case "farm_id":
          value = getters.value.farms[id - 1].id;
          _dispatch.getContainers(value);
          break;
        case "crawler":
          choosedCrawler.value = value != '';
          break;
        case "container_id":
          value = getters.value.containers[id-1].id;
          break;
        case "user_id":
          value = getters.value.owners[id-1].id;
          _dispatch.getWorkersById(value);
          break;
        case "worker_id":
          value = getters.value.workers[id].id;
          break;
        case "model":
          if (minersModels[value]) {
            Object.keys(minersModels[value]).forEach((res) => {
              formFields[res] = minersModels[value][res];
            });
          }
          break;
      }

      formFields[e.target.name] = value;
    };

    const checkAllFields = () => {
      const { ip_address, crawler, model } = formFields;
      if (!ipRegex.value.test(ip_address) || crawler.length == 0 || model.length == 0) {
        return true;
      }
      //check ip

      return false;
    };

    return {
      getters,
      formFields,
      ipRegex,
      updateOpened,
      checkAllFields,
      addNewMiner,
      msg,
      check,
      updateValue,
      invalidMiner,
      checkConnection,
      isOpenedModal,
      choosedCrawler,
      errors,
    };
  },
  data() {
    return {
      page: parseInt(this.$route.params.id),
    };
  },
  created() {
    this.curPage();
  },
  methods: {
    curPage() {
      this.$emit("curPage", "AddMiner");
    },
    customFormatter(date) {
      // custom formatter
      return moment(date).format("DD/MM/YYYY");
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
